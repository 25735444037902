<template>
  <b-modal
    id="modal-detailt-dishe"
    centered
    title="Detalle del plato"
  >
    <b-row v-if="item">
      <b-col
        cols="12"
      >
        <b-row class="overflow_hidden">
          <b-col cols="4">
            <div v-if="item.image !== null">
              <img
                :src="loadImage(item.image)"
                :alt="item.name"
                width="100%"
                height="100px"
              >
            </div>
            <div v-else>
              <img
                src="@/assets/images/default.png"
                :alt="item.name"
                width="100%"
                height="100px"
              >
            </div>
            <div class="title2 text-center">
              {{ item.name }}
            </div>
          </b-col>
          <b-col
            cols="8"
            class="p-0"
          >
            <div class="mb-2">
              <div class="title">
                Ingredientes
              </div>
              <div
                v-for="(ingredient, index) in item.ingredients"
                :key="index"
                class="title2"
              >
                <feather-icon icon="CheckCircleIcon" />
                {{ ingredient.title }}
              </div>
            </div>
            <div class="">
              <div class="title">
                Informaciòn Nutricional
              </div>
              <div v-if="item.nutritionalinformation.length > 0">
                <div
                  v-for="(info, index) in item.nutritionalinformation"
                  :key="index"
                  class="title2"
                >
                  <div
                    v-for="(inf) in JSON.parse(info.data.nutritional_values)"
                    :key="inf.title"
                  >
                    <v-icon
                      name="check-circle"
                    />
                    {{ inf.title + ' ' + inf.value }}
                  </div>
                </div>
              </div>
              <div v-else>
                <div class="title2 text-center">
                  No tiene información nutricional registrada.
                </div>
              </div>
            </div>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <template #modal-footer="{ close }">
      <b-button
        v-ripple.400="'rgba(186, 191, 199, 0.15)'"
        v-b-modal.modal-seconday
        variant="outline-secondary"
        @click="close()"
      >
        Cerrar
      </b-button>
    </template>
  </b-modal>
</template>

<script>
import {
  BModal, VBModal, BButton, BRow,
	BCol,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { API_ROUTES } from '../config/routes/api'

export default {
  components: {
    BModal,
    BButton,
    BRow,
    BCol,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  props: {
    item: {
      type: Object,
      default: null,
    },
  },
  methods: {
    loadImage(image) {
      return API_ROUTES.images.image + image
    },
  },
}
</script>

<style lang="scss" scoped>
.box {
  border: 1px solid grey;
  padding: 1rem;
  border-radius: .6rem;
}

.title {
  font-size: 18px;
  font-weight: 800;
  margin-bottom: 5px;
  color: #5E5878;
}

.title2 {
  font-size: 14px;
  margin-bottom: 5px;
  color: #5E5878;
}

.overflow_hidden {
  overflow: hidden !important;
}

</style>
