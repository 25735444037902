<template>
  <div>
    <b-card
      no-body
      class="p-2"
    >
      <base-actions-table
        :columns="fields"
        :records.sync="records"
        :busy="isBusy"
        :is-processing="isProcessing"
        :module="module"
        :paginate-serve="true"
        :pagination-serve="paginationServe"
        @creating="handleCreating"
        @editing="handleEditing"
        @deleting="handleDeleting"
        @change-page="handleChangePage"
        @change-limit="handleChangeLimit"
      >
        <!-- name -->
        <template #cell(name)="data">
          <div class="d-flex align-items-center">
            <template v-if="data.item.image !== null">
              <img
                :src="loadImage(data.item.image)"
                :alt="data.item.name"
                width="15%"
                height="72px"
              >
            </template>
            <template v-else>
              <img
                src="@/assets/images/default.png"
                :alt="data.item.name"
                width="15%"
                height="72px"
              >
            </template>
            <b class="ml-2">
              <div class="font-weight-bolder">
                {{ data.item.name }}
              </div>
            </b>
          </div>
        </template>

        <!-- actions -->
        <template v-slot:extendedactions="data">
          <b-button
            v-if="hasPermission(`${module}.details`)"
            variant="info"
            class="btn-icon mr-25"
            size="sm"
            @click.prevent="handleDetail(data.data.item)"
          >
            <feather-icon
              icon="EyeIcon"
            />
          </b-button>
        </template>
      </base-actions-table>
    </b-card>
    <detail-dishe-modal :item="item" />
  </div>
</template>

<script>
import {
	BButton,
	BCard,
} from 'bootstrap-vue'
import CRUDMixin from '@/mixins/CRUDMixin'
import PermissionMixin from '@/mixins/PermissionMixin'
import DetailDisheModal from '../components/DetailDisheModal'
import { API_ROUTES } from '../config/routes/api'
import BaseActionsTable from '@/components/base/BaseActionsTable'

export default {
  inject: ['disheRepository'],
	components: {
		BButton,
		BCard,
    DetailDisheModal,
    BaseActionsTable,
	},
  mixins: [CRUDMixin, PermissionMixin],
	data() {
		return {
			fields: [
				{
					key: 'name',
					label: 'Nombre',
				},
        {
					key: 'actions',
					label: 'Acciones',
				},
			],
      records: [],
      repository: 'disheRepository',
      module: 'dishe',
      isBusy: false,
      item: null,
      isNull: true,
		}
	},
  async mounted() {
    const me = this
    await me.handleLoadData()
  },
	methods: {
    handleCreating() {
      const me = this
      me.$router.push({ name: 'create-dishe' })
    },
    handleEditing(record) {
      const me = this
      me.$router.push({ name: 'update-dishe', params: { id: record.id } })
    },
    handleDeleting(record) {
			this.del(record.id)
		},
    handleDetail(item) {
      const me = this
      me.item = item
      me.$bvModal.show('modal-detailt-dishe')
    },
    loadImage(image) {
      return API_ROUTES.images.image + image
    },
    handleChangePage(page, limit) {
      const me = this
      console.log(page)
      me.handleLoadData(page, limit)
    },
    handleChangeLimit(limit) {
      const me = this
      console.log(limit)
      me.handleLoadData(1, limit)
    },
	},
}
</script>
<style lang="scss" scoped>
.margin-rigth {
	margin-right: 0.5rem;
}
</style>
