<template>
  <div>
    <b-form @submit.prevent>
      <b-row>
        <b-col md="6">
          <b-form-group
            label="Estado*"
            label-for="menu_type"
          >
            <v-select
              id="menu_type"
              v-model="formData.menu_type"
              label="text"
              placeholder="Seleccione"
              class="w-100"
              :options="typeMenus"
              :reduce="(option) => option.id"
            />
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group
            label="Hora*"
            label-for="time"
          >
            <b-form-input
              id="time"
              v-model="formData.time"
              class="form-control"
              type="time"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="6" />
        <b-col
          md="6"
          class="text-right"
        >
          <b-button
            type="button"
            variant="primary"
            :disabled="isBusy || $v.$invalid"
            @click="saveData"
          >
            <b-spinner
              v-if="isBusy"
              small
            />
            <v-icon
              v-else
              name="save"
            />
            Guardar
          </b-button>
        </b-col>
      </b-row>
    </b-form>
  </div>
</template>

<script>
import {
	BForm,
	BFormGroup,
	BButton,
	BCol,
	BRow,
	BSpinner,
  BFormInput,
} from 'bootstrap-vue'
import { required } from 'vuelidate/lib/validators'
import vSelect from 'vue-select'
import CRUDMixin from '@/mixins/CRUDMixin'

export default {
	inject: ['orderScheduleRepository'],
	components: {
		BForm,
		BFormGroup,
		BButton,
		BCol,
		BFormInput,
		BRow,
		BSpinner,
		vSelect,
	},
  mixins: [CRUDMixin],
	props: {
		record: {
			type: Object,
			default: null,
		},
	},
	data() {
		return {
			formData: {
				menu_type: '',
				time: null,
			},
      typeMenus: [
				{
					id: '1',
					text: 'Desayuno',
				},
				{
					id: '2',
					text: 'Almuerzo',
				},
				{
					id: '3',
					text: 'Cena',
				},
			],
			isBusy: false,
      repository: 'orderScheduleRepository',
		}
	},
	async mounted() {
		const me = this
		if (me.record) {
			me.formData = { ...me.record }
      if (me.state) me.formData.state = me.state
		}
	},
	validations: {
    formData: {
      menu_type: {
        required,
      },
      time: {
        required,
      },
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
