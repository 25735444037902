<template>
  <div>
    <b-card
      no-body
    >
      <base-actions-table
        :columns="fields"
        :records="records"
        :busy="isBusy"
        :is-processing="isProcessing"
        :module="module"
        @creating="handleCreating"
        @editing="handleEditing"
        @deleting="handleDeleting"
      >
        <template #cell(menu_type)="data">
          {{ nameDiet(data.item.menu_type) }}
        </template>
      </base-actions-table>
    </b-card>
    <b-modal
      id="orderSchedule-modal"
      title="Horario"
      hide-footer
    >
      <b-card-text>
        <add-edit-schedule-form
          ref="form"
          :record="record"
          @save="save"
          @edit="edit"
        />
      </b-card-text>
    </b-modal>
  </div>
</template>
<script>
import {
  BModal,
  BCardText,
  BCard,
} from 'bootstrap-vue'
import CRUDMixin from '@/mixins/CRUDMixin'
import PermissionMixin from '@/mixins/PermissionMixin'
import AddEditScheduleForm from './components/AddEditScheduleForm'
import BaseActionsTable from '@/components/base/BaseActionsTable'

export default {
    name: 'OrderSchedule',
    inject: ['orderScheduleRepository'],
    components: {
      BCard,
      BCardText,
      BModal,
      AddEditScheduleForm,
      BaseActionsTable,
    },
    mixins: [CRUDMixin, PermissionMixin],
    data() {
      return {
        fields: [
          {
            key: 'menu_type',
            label: 'Tipo de menú',
            sortable: true,
          },
          {
            key: 'time',
            label: 'Hora máxima de pedido',
          },
          {
            key: 'actions',
            label: 'Acciones',
          },
        ],
        records: [],
        filter: null,
        filterOn: [],
        record: null,
        repository: 'orderScheduleRepository',
        module: 'orderSchedule',
        isBusy: false,
      }
    },

    async mounted() {
      const me = this
      await me.handleLoadData()
    },

    methods: {
      handleCreating() {
        this.record = null
        this.$bvModal.show('orderSchedule-modal')
      },

      handleEditing(record) {
        this.record = record
        this.$bvModal.show('orderSchedule-modal')
      },

      handleDeleting(record) {
        this.del(record.id)
      },
      nameDiet(type) {
        let text = null

        if (type == '1') {
          text = 'Desayuno'
        } else if (type == '2') {
          text = 'Almuerzo'
        } else {
          text = 'Cena'
        }
        return text
      },
    },
  }
</script>

<style>

</style>
